export const stageConfig = {
  api: {
    baseUrl: 'https://vn-indexer-stage.bf.works',
  },
  season: {
    current: 4,
    totalStages: 6,
  },
  venomNetwork: {
    url: 'https://beta-tnlmsa4r10yiwtnddchmstl7bxzh5ulw.venom.network/',
    claim:
      'https://beta-tnlmsa4r10yiwtnddchmstl7bxzh5ulw.venom.network/claim/season-3',
  },
  telegramBotApp: {
    url: 'https://t.me/VenomNetworkAppTestBot/main',
    deepLink: 'tg://resolve?domain=VenomNetworkAppTestBot&appname=main',
  },
};
